import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {
  Container,
  Row,
  Col,
  useScreenClass,
  Hidden,
  Visible,
} from "react-grid-system"

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.darker};
  color: ${(props) => props.theme.colors.white};
  overflow: hidden;
  padding-bottom: 5.5rem;
  padding-top: 3.25rem;
  position: relative;
`

const SectionAnchor = styled.a`
  height: 0;
  left: 0;
  position: absolute;
  top: -100px;
  width: 0;
  visibility: hidden;
`

const Item = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.gray48};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 0;
  overflow: hidden;
  padding-bottom: 0.7rem;
  position: relative;
`

const H2 = styled.h2`
  margin-bottom: 3.5rem;
`

const PreviewText = styled.div`
  margin-bottom: 0.5rem;
`

const ReadMoreLink = styled.a`
  display: flex;
  height: 0;
  justify-content: flex-end;
  margin-bottom: 33px;
  margin-left: auto;
  margin-top: 0.5rem;
  position: unset;
  &::after {
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`

const ReadMoreIcon = styled(GatsbyImage)`
  opacity: 1;
  position: absolute !important;
  transition: opacity ${(props) => props.theme.styles.transition};
  ${Item}:hover & {
    opacity: 0;
  }
`
const ReadMoreIconHover = styled(ReadMoreIcon)`
  opacity: 0;
  ${Item}:hover & {
    opacity: 1;
  }
`

const ReadMoreLinkTitle = styled.span`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.serif};
  padding-right: 60px;
  transition: color ${(props) => props.theme.styles.transition};
  ${Item}:hover & {
    color: ${(props) => props.theme.colors.highlight};
  }
`

const Careers = () => {
  const data = useStaticQuery(graphql`
    {
      blue: file(relativePath: { eq: "arrow-right.png" }) {
        childImageSharp {
          gatsbyImageData(width: 33, height: 33, quality: 100, layout: FIXED)
        }
      }
      dark: file(relativePath: { eq: "arrow-right-gray.png" }) {
        childImageSharp {
          gatsbyImageData(width: 33, height: 33, quality: 100, layout: FIXED)
        }
      }
      allContentfulCareers {
        edges {
          node {
            id
            name
            slug
            overview {
              overview
            }
            url
          }
        }
      }
    }
  `)
  const allCareers = data.allContentfulCareers.edges
  const screenClass = useScreenClass()
  return (
    <Section>
      <SectionAnchor id="careers" />
      <Container
        style={{
          paddingLeft: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
          paddingRight: ["md", "lg", "xl"].includes(screenClass)
            ? "3rem"
            : "1.5625rem",
        }}
      >
        <Row>
          <Col lg={8} xl={8} offset={{ lg: 2 }}>
            <H2 className={`headline tagline`}>Want to join our crew?</H2>
            <Row align="stretch">
              {allCareers.map(({ node: career }, index) => (
                <Col
                  md={6}
                  key={index}
                  style={{
                    paddingBottom: "2.125rem",
                  }}
                >
                  <Item>
                    <div>
                      <h3>{career.name}</h3>
                      <PreviewText
                        dangerouslySetInnerHTML={{
                          __html: career.overview.overview,
                        }}
                      />
                    </div>
                    <ReadMoreLink href={`${career.url}`} target={`_blank`}>
                      <ReadMoreLinkTitle>Learn More</ReadMoreLinkTitle>
                      <ReadMoreIcon
                        image={data.blue.childImageSharp.gatsbyImageData}
                      />
                      <ReadMoreIconHover
                        image={data.dark.childImageSharp.gatsbyImageData}
                      />
                    </ReadMoreLink>
                  </Item>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Careers
