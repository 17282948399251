import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col, useScreenClass, Hidden } from "react-grid-system"
import styled from "styled-components"
import { rgba } from "polished"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { useInView, InView } from "react-intersection-observer"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/main"
import CtaBanner from "../components/ctaBanner"
import Careers from "../components/careers"

const Content = (props) => {
  let content
  if (props.content === null) content = ``
  if (props.content.shortText) {
    content = (
      <span
        dangerouslySetInnerHTML={{ __html: props.content.shortText }}
        className={props.className}
        {...props}
      />
    )
  }
  if (props.content.richText && props.content.richText) {
    content = (
      <div className={props.className} {...props}>
        {documentToReactComponents(JSON.parse(props.content.richText.raw))}
      </div>
    )
  }
  return content
}

const StyledMain = styled(Main)`
  margin-top: 0;
  padding-top: 0;
`

const SectionHero = styled.section`
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  min-height: 100vh;
  position: relative;
  padding-bottom: 64px;
  padding-top: 64px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    padding-bottom: 2.3rem;
    padding-top: 2.3rem;
    min-height: 490px;
  }
`

const HeroImage = styled(GatsbyImage)`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`

const Headline = styled.p`
  color: ${(props) => props.theme.colors.white};
  font-family: ${(props) => props.theme.fonts.serif};
  font-size: 3rem;
  line-height: 1;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    font-size: 1.78rem;
    margin-top: 80px;
  }
`

const Section = styled.section`
  position: relative;
  padding-bottom: 64px;
  padding-top: 64px;
  @media only screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobile}) {
    padding-bottom: 2.3rem;
    padding-top: 2.3rem;
  }
`

const SectionWhy = styled(Section)`
  background-color: ${(props) => rgba(props.theme.colors.gray22, 0.08)};
`

const Card = styled.div`
  border: 1px solid ${(props) => props.theme.colors.gray70};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.4rem 1.8rem;
`

const CardTitle = styled.h3`
  border-bottom: 1px solid ${(props) => props.theme.colors.highlight};
  display: block;
  font-size: 1.55rem;
  line-height: 1.290322580645161;
  margin-bottom: 1em;
  margin-right: auto;
  padding-bottom: 0.4193548387096774em;
`

const AboutPage = (props) => {
  const { cta, seoTitle, seoDescription, seoHelmet, openGraphImage } =
    props.data.contentfulPage
  const screenClass = useScreenClass()
  const [ref, inView, entry] = useInView({
    rootMargin: "0px 0px -99% 0px",
  })

  useEffect(() => {
    if (inView) {
      document.body.classList.add("is-intersecting__true")
    } else {
      document.body.classList.remove("is-intersecting__true")
    }
  }, [inView])

  return (
    <>
      <SEO
        title={seoTitle ? seoTitle : ``}
        description={
          seoDescription && seoDescription.seoDescription
            ? seoDescription.seoDescription
            : ``
        }
        pageUrl={props?.location?.href}
        image={openGraphImage?.localFile?.publicURL}
      />
      <Layout>
        <StyledMain>
          <SectionHero ref={ref} id={`about__hero`}>
            <HeroImage
              image={props.data.heroImage.childImageSharp.gatsbyImageData}
              style={{ position: `absolute` }}
            />
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row align="center">
                <Col lg={8} offset={{ lg: 2 }}>
                  <Headline
                    dangerouslySetInnerHTML={{
                      __html: props.data.aboutHeadline.shortText,
                    }}
                    className={`headline`}
                  />
                </Col>
              </Row>
            </Container>
            <p>{inView.toString()}</p>
          </SectionHero>
          <SectionWhy id={`about__why`}>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <h2 className={`headline`}>Why we do what we do</h2>
                  <Content content={props.data.aboutOverview} />
                </Col>
              </Row>
            </Container>
          </SectionWhy>
          <Section>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <h2 className={`headline`}>Our focus</h2>
                  <Content
                    content={props.data.aboutFocus}
                    style={{ marginBottom: `1.25rem` }}
                  />
                  <Row>
                    <Col
                      sm={12}
                      lg={4}
                      style={{
                        paddingBottom: `16px`,
                      }}
                    >
                      <Card>
                        <CardTitle>01</CardTitle>
                        <Content content={props.data.aboutFocusPoint1} />
                      </Card>
                    </Col>
                    <Col
                      sm={12}
                      lg={4}
                      style={{
                        paddingBottom: `16px`,
                      }}
                    >
                      <Card>
                        <CardTitle>02</CardTitle>
                        <Content content={props.data.aboutFocusPoint2} />
                      </Card>
                    </Col>
                    <Col
                      sm={12}
                      lg={4}
                      style={{
                        paddingBottom: `16px`,
                      }}
                    >
                      <Card>
                        <CardTitle>03</CardTitle>
                        <Content content={props.data.aboutFocusPoint3} />
                      </Card>
                    </Col>
                  </Row>
                  <Content content={props.data.aboutFocusAfter} />
                </Col>
              </Row>
            </Container>
          </Section>
          <CtaBanner {...cta} />
          <Careers />
        </StyledMain>
      </Layout>
    </>
  )
}

export const aboutPageQuery = graphql`
  query AboutPageQuery {
    heroImage: file(relativePath: { eq: "about-page-hero.png" }) {
      ...fluidImage
    }
    contentfulPage(slug: { eq: "about" }) {
      id
      slug
      name
      mainHeadline
      text {
        raw
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
      openGraphImage {
        localFile {
          publicURL
        }
      }
      cta {
        id
        name
        ctaTitle
        ctaBody
        ctaButton
      }
    }
    aboutHeadline: contentfulGeneralUseContent(
      key: { in: ["about-headline"] }
    ) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
    aboutOverview: contentfulGeneralUseContent(
      key: { in: ["about-overview"] }
    ) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
    aboutFocus: contentfulGeneralUseContent(key: { in: ["about-focus"] }) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
    aboutFocusAfter: contentfulGeneralUseContent(
      key: { in: ["about-focus-after"] }
    ) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
    aboutFocusPoint1: contentfulGeneralUseContent(
      key: { in: ["about-focus-point-1"] }
    ) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
    aboutFocusPoint2: contentfulGeneralUseContent(
      key: { in: ["about-focus-point-2"] }
    ) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
    aboutFocusPoint3: contentfulGeneralUseContent(
      key: { in: ["about-focus-point-3"] }
    ) {
      id
      key
      name
      shortText
      richText {
        raw
      }
    }
  }
`

export default AboutPage
